import React from 'react';
import { Helmet } from 'react-helmet';

export default class AppHead extends React.Component {
    render () {
		const richSnippet = {
			"@context": "https://schema.org",
			"@graph": [
				{
					"@type": "Organization",
					"@id": "https://cartes-records.fr/#organization",
					"name": "Cartes Records",
					"url": "https://cartes-records.fr",
					"sameAs": [
						"https://facebook.com/cartes-records",
						"https://www.instagram.com/cartes.records/",
						"https://cartesrecords.bandcamp.com/",
						"https://www.youtube.com/channel/UCC0i1jgwq-fTiLzZdsdr3ow",
						"https://soundcloud.com/cartesrecords"
					],
					"logo": {
						"@type": "ImageObject",
						"@id": "https://cartes-records.fr/#logo",
						"url": "https://cartes-records.fr/logo512.png",
						"width": 512,
						"height": 512,
						"caption": "Cartes Records"
					},
					"image": {
						"@id": "https://cartes-records.fr/#logo"
					}
				},
				{
					"@type": "WebSite",
					"@id": "https://cartes-records.fr/#website",
					"url": "https://cartes-records.fr",
					"name": "Cartes Records",
					"publisher": {
					"@id": "https://cartes-records.fr/#organization"
					},
					// "potentialAction": {
					//   "@type": "SearchAction",
					//   "target": "https://cartes-records.fr/?s={search_term_string}",
					//   "query-input": "required name=search_term_string"
					// }
				},
			]
		};
		
		return (
	        <Helmet titleTemplate="%s | Cartes Records" defaultTitle="Cartes Records">  
	            <meta
	              name="description"
	              content="Electronic music records, events promoter for life enthusiasts, agency and most of all a family dedicated to the love of music. Born in Lyon, France in 2017."
	            />
	            <meta property="og:title" content='Electronic music label and party people from Lyon | Cartes Records' />
	            <meta property="og:description" content='Electronic music records, events promoter for life enthusiasts, agency and most of all a family dedicated to the love of music. Born in Lyon, France in 2017.' />
	            <meta property="og:image" content='/logo512.png' />
				<script type="application/ld+json">
					{JSON.stringify(richSnippet)}
				</script>
	        </Helmet>
	    );
	}
}	