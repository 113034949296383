import {AUTH_SUCCES, AUTH_FAIL, LOGOUT_SUCCES} from '../Store/constants';

const initialState = {
	token: false,
	expiration: false,
	error: [],
}

function authReducers(state = initialState, action) {
/*	console.log(action);*/
	switch(action.type) {
		case AUTH_SUCCES: {
			return Object.assign({}, state, {
			    token: action.token,
			    expiration: action.expiration
			});
		}
		case AUTH_FAIL: {
			return {
				...state,
			    error: state.error.concat(action.payload)
			};
		}
		case LOGOUT_SUCCES: {
			return Object.assign({}, state, {
			    token: false,
			    expiration: false
			});
		}
		default: 
            return state;
	}
};

export default authReducers;