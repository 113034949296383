import React from 'react';
import {
  Switch,
  Redirect,
  Route
} from "react-router-dom";

import loadable from '@loadable/component'
import useGoogleAnalytics from '../Tracking/analytics';

const Home = loadable(() => import(/* webpackChunkName: "Home" */ '../Home'))
const ArtistIndex = loadable(() => import(/* webpackChunkName: "ArtistIndex" */ '../Artists/Index'))
const ArtistView = loadable(() => import(/* webpackChunkName: "ArtistView" */ '../Artists/View'))
const PodcastIndex = loadable(() => import(/* webpackChunkName: "PodcastIndex" */ '../Podcast/Index'))
const PodcastView = loadable(() => import(/* webpackChunkName: "PodcastView" */ '../Podcast/View'))
const ProductionIndex = loadable(() => import(/* webpackChunkName: "ProductionIndex" */ '../Production/Index'))
const productionsView = loadable(() => import(/* webpackChunkName: "productionsView" */ '../Production/View'))
const Page404 = loadable(() => import(/* webpackChunkName: "Page404" */ '../Error/404'))


export default function Routes () {
    useGoogleAnalytics();
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/artists" component={ArtistIndex}/>
            <Route path="/artists/:urlKey" component={ArtistView}/>
            <Route exact path="/podcasts" component={PodcastIndex}/>
            <Route path="/podcasts/:urlKey" component={PodcastView}/>
            <Route exact path="/productions" component={ProductionIndex}/>
            <Route path="/productions/:urlKey" component={productionsView}/>
            <Route exact path="/not-found" component={Page404}/>
            <Route path="/">
                <Redirect to='/not-found'/>
            </Route>
        </Switch>
    );
}