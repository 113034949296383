import {	
			ARTISTS_LIST_FETCHING,
			ARTISTS_VIEW_LOADED, 
			ARTISTS_PRODUCTION_LOADED, 
			ARTISTS_PODCAST_LOADED, 
			ARTISTS_TRACK_LOADED,
			ARTISTS_VIDEO_LOADED,
			ARTISTS_VIEW_CLEAR,
			ARTISTS_INTERN_LOADED,
			ARTISTS_EXTERN_LOADED,
			SET_HOME_ARTIST,
	} from '../Store/constants';

const initialState = {
	isFetching: false,
	intern: {
		index: [],
		ids: [],
		load: false,
		fetched: [],
		next: false
	},
	extern: {
		index: [],
		ids: [],
		load: false,
		fetched: [],
		next: false
	},
	view: {
		info: [],
		infoLoad: false,
		production: [],
		productionLoad: false,
		podcast: [],
		podcastLoad: false,
		track: [],
		trackLoad: false,
		video: [],
		videoLoad: false,
	},
	homeActive: false,
}

function artistReducers(state = initialState, action) {
	switch(action.type) {
		case ARTISTS_LIST_FETCHING: {
			return Object.assign({}, state, {
				isFetching: true,
			});
		}
		case ARTISTS_INTERN_LOADED: {
			return Object.assign({}, state, {
				isFetching: false,
				intern: {
					index: state.intern.index.concat(action.content),
					ids: state.intern.ids.concat(action.ids),
					load: true,
					fetched: state.intern.fetched.concat(action.fetched),
					next: action.next
				},
			    homeActive: Object.entries(action.content).shift()[1] // Transfor object ot array + get the first element + get the value
			});
		}
		case ARTISTS_EXTERN_LOADED: {
			return Object.assign({}, state, {
				isFetching: false,
				extern: {
					index: state.extern.index.concat(action.content),
					ids: state.extern.ids.concat(action.ids),
					load: true,
					fetched: state.extern.fetched.concat(action.fetched),
					next: action.next
				}
			});
		}
		case ARTISTS_VIEW_LOADED: {
			return Object.assign({}, state, {
				view: {
					...state.view,
					info: action.content,
					infoLoad: true,
				}
			});
		}
		case ARTISTS_PRODUCTION_LOADED: {
			if (action.content) {
				return Object.assign({}, state, {
					view: {
						...state.view,
						production: state.view.production.concat(action.content),
						productionLoad: true,
					}
				});
			} else {
				return Object.assign({}, state, {
				    view: {
				    	...state.view,
				    	productionLoad: true,
				    }
				});
			}
		}
		case ARTISTS_PODCAST_LOADED: {
			if (action.content) {
				return Object.assign({}, state, {
					view: {
				    	...state.view,
				    	podcast: state.view.podcast.concat(action.content),
						podcastLoad: true,
				    }
				});
			} else {
				return Object.assign({}, state, {
			    view: {
			    	...state.view,
					podcastLoad: true,
			    }
			});
			}
			
		}
		case ARTISTS_TRACK_LOADED: {
			if (action.content) {
				return Object.assign({}, state, {
					view: {
				    	...state.view,
				    	track: state.view.track.concat(action.content),
						trackLoad: true,
				    }
				});
			} else {
				return Object.assign({}, state, {
			    	view: {
			        	...state.view,
			    		trackLoad: true,
			        }
				});
			}
		}
		case ARTISTS_VIDEO_LOADED: {
			if (action.content) {
				return Object.assign({}, state, {
					view: {
				    	...state.view,
				    	video: state.view.video.concat(action.content),
						videoLoad: true,
				    }
				});
			} else {
				return Object.assign({}, state, {
			    	view: {
			        	...state.view,
			    		videoLoad: true,
			        }
			});
			}
		}
		case SET_HOME_ARTIST: {
			return {
				...state,
			    homeActive: action.content
			};
		}
		case ARTISTS_VIEW_CLEAR: {
			return {
				...state,
		    	view: {
		        	...initialState.view,
		        }
			};
		}
		default: 
            return state;
	}
};

export default artistReducers;