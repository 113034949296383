import { 
	TOGGLE_PLAY, 
	TOGGLE_VOLUME_VISIBILITY, 
	VOLUME_UPDATED, 
	TOGGLE_VOLUME_MUTE,
	SET_ACTIVE_TRACK,
	SET_ACTIVE_TRACK_STREAM_URL,
	SET_FOLLOWING_TRACKS,
	SET_PREVIOUS_ACTIVE_TRACK,
	SET_TRACK_RELATED,
} from '../../Store/constants';

const initialeState = {
	activeTrackInfo: {},
	activeTrackRelated: {},
	player: {
		isPlaying: false,
		volume: 1,
		volumeVisible: false,
		volumeMute: false,
	},
	trackHistory: [],
	followingTracks: [],
	streamUrl: false,
}

function playerReducers (state = initialeState, action) {
	switch (action.type) {
		case SET_ACTIVE_TRACK: {
			return Object.assign({}, state, {
				...state,
				activeTrackInfo: action.payload,
				trackHistory: action.trackHistory,
				streamUrl: false,
			});
		}

		case SET_PREVIOUS_ACTIVE_TRACK: {
			return Object.assign({}, state, {
				...state,
				activeTrackInfo: action.payload,
				followingTracks: action.followingTracks,
				streamUrl: false,
			});
		}

		case SET_ACTIVE_TRACK_STREAM_URL: {
			return {
				...state,
				player: {
					...state.player,
					// isPlaying: true
				},
				streamUrl: action.payload,
			}
		}

		case SET_TRACK_RELATED: {
			return Object.assign({}, state, {
				...state,
				activeTrackRelated: action.payload,
			});
		}

		case TOGGLE_PLAY: {
			const newState = !state.player.isPlaying;
			return {
				...state,
				player: {
					...state.player,
					isPlaying: newState	
				}
			}
		}

		case TOGGLE_VOLUME_VISIBILITY: {
			const newState = !state.player.volumeVisible;
			return {
				...state,
				player: {
					...state.player,
					volumeVisible: newState	
				}
			}
		}

		case VOLUME_UPDATED: {
			return {
				...state,
				player: {
					...state.player,
					volume: action.payload	
				}
			}
		}

		case TOGGLE_VOLUME_MUTE: {
			const newState = !state.player.volumeMute;
			return {
				...state,
				player: {
					...state.player,
					volumeMute: newState	
				}
			}
		}

		case SET_FOLLOWING_TRACKS: {
			return {
				...state,
				followingTracks: action.payload,
			}
		}

		default :
			return state;
	}
};

export default playerReducers;