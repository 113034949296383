import { 
	PRODUCTION_LIST_FETCHING,
	PRODUCTION_VIEW_LOADED, 
	PRODUCTION_TRACK_LOADED,
	PRODUCTION_VIDEO_LOADED, 
	PRODUCTION_VIEW_CLEAR,
	PRODUCTION_INTERN_LOADED,
	PRODUCTION_EXTERN_LOADED } from '../Store/constants';

const initialState = {
	isFetching: false,
	intern: {
		index: [],
		ids: [],
		load: false,
		fetched: [],
		next: false
	},
	extern: {
		index: [],
		ids: [],
		load: false,
		fetched: [],
		next: false
	},
	view: {
		info: [],
		infoLoad: false,
		track: [],
		trackLoad: false,
		video: [],
		videoLoad: false,
	},
}

function productionReducers (state = initialState, action) {
	switch(action.type) {
		case PRODUCTION_LIST_FETCHING: {
			return Object.assign({}, state, {
				isFetching: true,
			});
		}
		case PRODUCTION_INTERN_LOADED: {
			return Object.assign({}, state, {
				isFetching: false,
				intern: {
					index: state.intern.index.concat(action.content),
					ids: state.intern.ids.concat(action.ids),
					load: true,
					fetched: state.intern.fetched.concat(action.fetched),
					next: action.next
				},
			});
		}
		case PRODUCTION_EXTERN_LOADED: {
			return Object.assign({}, state, {
				isFetching: false,
			    extern: {
					index: state.extern.index.concat(action.content),
					ids: state.extern.ids.concat(action.ids),
					load: true,
					fetched: state.extern.fetched.concat(action.fetched),
					next: action.next
				},
			});
		}
		case PRODUCTION_VIEW_LOADED: {
			return Object.assign({}, state, {
			    view: {
					...state.view,
					info: action.content,
					infoLoad: true,
				}
			});
		}
		case PRODUCTION_TRACK_LOADED: {
			if (action.content) {
				return Object.assign({}, state, {
				    view: {
				    	...state.view,
						track: state.view.track.concat(action.content),
						trackLoad: true,
					}
				});
			} else {
				return Object.assign({}, state, {
			        view: {
			    		...state.view,
			    		trackLoad: true,
			    	}
			});
			}
		}
		case PRODUCTION_VIDEO_LOADED: {
			if (action.content) {
				return Object.assign({}, state, {
				    view: {
						...state.view,
						video: state.view.video.concat(action.content),
						videoLoad: true,
					}
				});
			} else {
				return Object.assign({}, state, {
			        view: {
			    		...state.view,
			    		videoLoad: true,
			    	}
			});
			}
		}
		case PRODUCTION_VIEW_CLEAR: {
			return {
				...state,
		    	view: {
		        	...initialState.view,
		        }
			};
		}
		default: 
            return state;
	}
}

export default productionReducers;