import { createStore, combineReducers, applyMiddleware, compose  } from 'redux';
import thunk from "redux-thunk";

import headerReducers from '../Components/Header/header-reducers';
import soundcloudAuthReducers from '../API-Soundcloud/soundcloud-auth-reducers';
import authReducers from '../API/auth-reducers';
import artistReducers from '../API/artist-reducers';
import podcastReducers from '../API/podcast-reducers';
import productionReducers from '../API/production-reducers';
import directionReducers from '../API/direction-reducers';
import playerReducers from '../Components/Player/player-reducers';

let storeEnhancers = compose;

const mergedReducers = combineReducers (
	{
		authReducers, 
		artistReducers, 
		headerReducers, 
		playerReducers, 
		podcastReducers, 
		productionReducers, 
		directionReducers,
		soundcloudAuthReducers
	}
);

export default function configureStore(preloadedState, client = false) {
    const store = createStore(
    	mergedReducers,
    	preloadedState,
    	storeEnhancers(applyMiddleware(thunk))
    );

    if (client && process.env.NODE_ENV === "development") {
    	window.store = store;
    }

    return store;
}