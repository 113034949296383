import {CLOSE_MENU, OPEN_MENU, TOGGLE_MENU} from '../../Store/constants';

const initialState = {
    isMenuOpen: false
};

function headerReducers(state = initialState, action) {
	if (action.type === OPEN_MENU) {
		return Object.assign({}, state, {
	      isMenuOpen: true
	    });
	}
	if (action.type === CLOSE_MENU) {
		return Object.assign({}, state, {
	      isMenuOpen: false
	    });
	}
	if (action.type === TOGGLE_MENU) {
		const newState = !state.isMenuOpen;
		return Object.assign({}, state, {
	      isMenuOpen: newState
	    });
	}
	return state;
};

export default headerReducers;