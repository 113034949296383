import { 
	ALL_DIRECTION_LOADED, 
	HEART_LOADED, 
	CLUB_LOADED,
	DIAMOND_LOADED,
	SPADE_LOADED,
} from '../Store/constants';

const initialState = {
	directionIndex: [],
	directionIndexLoaded: false,
	heartCard: {
		lastProduction: [],
		lastProductionLoaded: false,
		lastPodcast: [],
		lastPodcastLoaded: false,
	},
	spadeCard: {
		lastProduction: [],
		lastProductionLoaded: false,
		lastPodcast: [],
		lastPodcastLoaded: false,
	},
	diamondCard: {
		lastProduction: [],
		lastProductionLoaded: false,
		lastPodcast: [],
		lastPodcastLoaded: false,
	},
	clubCard: {
		lastProduction: [],
		lastProductionLoaded: false,
		lastPodcast: [],
		lastPodcastLoaded: false,
	},
}

function directionReducers(state = initialState, action) {
	switch(action.type) {
		case ALL_DIRECTION_LOADED: {
			return Object.assign({}, state, {
				...state,
			    directionIndex: action.content,
			    directionIndexLoaded: true
			});
		}
		case HEART_LOADED: {
			return Object.assign({}, state, {
				...state,
			    heartCard : {
			    	lastProduction: Object.assign(state.heartCard.lastProduction, action.productions),
			    	lastProductionLoaded: true,
			    	lastPodcast: Object.assign(state.heartCard.lastPodcast, action.podcasts),
			    	lastPodcastLoaded: true,
				}
			});
		}
		case CLUB_LOADED: {
			return Object.assign({}, state, {
				...state,
			    clubCard : {
			    	lastProduction: Object.assign(state.clubCard.lastProduction, action.productions),
			    	lastProductionLoaded: true,
			    	lastPodcast:Object.assign(state.clubCard.lastPodcast, action.podcasts),
			    	lastPodcastLoaded: true
				}
			});
		}
		case DIAMOND_LOADED: {
			return Object.assign({}, state, {
				...state,
			    diamondCard : {
			    	lastProduction: Object.assign(state.diamondCard.lastProduction, action.productions),
			    	lastProductionLoaded: true,
			    	lastPodcast: Object.assign(state.diamondCard.lastPodcast, action.podcasts),
			    	lastPodcastLoaded: true,
				}
			});
		}
		case SPADE_LOADED: {
			return Object.assign({}, state, {
				...state,
			    spadeCard : {
			    	lastProduction: Object.assign(state.spadeCard.lastProduction, action.productions),
			    	lastProductionLoaded: true,
			    	lastPodcast: Object.assign(state.spadeCard.lastPodcast, action.podcasts),
			    	lastPodcastLoaded: true
				}
			});
		}
		default: 
            return state;
	}
};

export default directionReducers;