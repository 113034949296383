export const homeProductionCount = 2;
export const homePodcastCount = 1;

// SOUNDCLOUD //

export const soundcloudAccess = (grant_type, refresh_token = null, client_id = null, client_secret = null) => {
    
	if (grant_type === "client_credentials") {
		return {
			"client_id": client_id,
			"client_secret": client_secret,
			"grant_type": grant_type,
		}
	} else if (grant_type === "refresh_token") {
		return {
			"client_id": client_id,
			"client_secret": client_secret,
			"grant_type": grant_type,
			"refresh_token": refresh_token,
		}
	}
}

export const apiSoundcloudUrl = "https://api.soundcloud.com";

// API //
export const userAccess = {
	"username": "Front",
	"password": "front"
};

// AUTH //
export const AUTH_SUCCES = 'AUTH_SUCCES';
export const AUTH_FAIL = 'AUTH_FAIL';
export const LOGOUT_SUCCES = 'LOGOUT_SUCCES';

// ARTIST //
export const ARTISTS_LIST_FETCHING = 'ARTISTS_LIST_FETCHING';
export const ARTISTS_VIEW_LOADED = 'ARTISTS_VIEW_LOADED';
export const GET_ARTIST_FROM_PATH = 'GET_ARTIST_FROM_PATH';
export const ARTISTS_PODCAST_LOADED = 'ARTISTS_PODCAST_LOADED';
export const ARTISTS_PRODUCTION_LOADED = 'ARTISTS_PRODUCTION_LOADED';
export const ARTISTS_VIDEO_LOADED = 'ARTISTS_VIDEO_LOADED';
export const ARTISTS_VIEW_CLEAR = 'ARTISTS_VIEW_CLEAR';
export const ARTISTS_INTERN_LOADED = 'ARTISTS_INTERN_LOADED';
export const ARTISTS_EXTERN_LOADED = 'ARTISTS_EXTERN_LOADED';
export const SET_HOME_ARTIST = 'SET_HOME_ARTIST';

// PODCAST //
export const PODCAST_LIST_FETCHING = 'PODCAST_LIST_FETCHING';
export const PODCAST_VIEW_LOADED = 'PODCAST_VIEW_LOADED';
export const PODCAST_TRACK_LOADED = 'PODCAST_TRACK_LOADED';
export const PODCAST_VIDEO_LOADED = 'PODCAST_VIDEO_LOADED';
export const PODCAST_VIEW_CLEAR = 'PODCAST_VIEW_CLEAR';
export const PODCAST_INTERN_LOADED = 'PODCAST_INTERN_LOADED';
export const PODCAST_EXTERN_LOADED = 'PODCAST_EXTERN_LOADED'; 

// PRODUCTION //
export const PRODUCTION_LIST_FETCHING = 'PRODUCTION_LIST_FETCHING';
export const PRODUCTION_VIEW_LOADED = 'PRODUCTION_VIEW_LOADED';
export const PRODUCTION_VIEW_CLEAR = 'PRODUCTION_VIEW_CLEAR';
export const PRODUCTION_TRACK_LOADED = 'PRODUCTION_TRACK_LOADED';
export const PRODUCTION_VIDEO_LOADED = 'PRODUCTION_VIDEO_LOADED';
export const PRODUCTION_INTERN_LOADED = 'PRODUCTION_INTERN_LOADED';
export const PRODUCTION_EXTERN_LOADED = 'PRODUCTION_EXTERN_LOADED'; 

// TRACK //
export const ARTISTS_TRACK_LOADED = 'ARTISTS_TRACK_LOADED';
export const SET_TRACK_RELATED = 'SET_TRACK_RELATED';

// PLAYER // 
export const FORCE_PAUSE = 'FORCE_PAUSE';
export const SET_ACTIVE_TRACK = 'SET_ACTIVE_TRACK';
export const SET_ACTIVE_TRACK_STREAM_URL = 'SET_ACTIVE_TRACK_STREAM_URL';
export const SET_FOLLOWING_TRACKS = 'SET_FOLLOWING_TRACKS';
export const SET_PREVIOUS_ACTIVE_TRACK = 'SET_PREVIOUS_ACTIVE_TRACK';
export const TOGGLE_PLAY = 'TOGGLE_PLAY';
export const TOGGLE_VOLUME_VISIBILITY = 'TOGGLE_VOLUME_VISIBILITY';
export const VOLUME_UPDATED = 'VOLUME_UPDATED';
export const TOGGLE_VOLUME_MUTE = 'TOGGLE_VOLUME_MUTE';

// DIRECTION //
export const ALL_DIRECTION_LOADED = 'ALL_DIRECTION_LOADED';
export const HEART_LOADED = 'HEART_LOADED';
export const CLUB_LOADED = 'CLUB_LOADED';
export const DIAMOND_LOADED = 'DIAMOND_LOADED';
export const SPADE_LOADED = 'SPADE_LOADED';
export const CLEAR = 'CLEAR';

// MENU //
export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const TOGGLE_MENU = 'TOGGLE_MENU';