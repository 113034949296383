import React from 'react';
import Routes from '../Routes';
import AppHead from './appHead';
import loadable from '@loadable/component'
import './App.scss';

const Header = loadable(() => import(/* webpackChunkName: "Header" */ '../Header'))
const Player = loadable(() => import(/* webpackChunkName: "Player" */ '../Player/Player'))
const AlertError = loadable(() => import(/* webpackChunkName: "AlertError" */ '../Error/AlertError'))
const Footer = loadable(() => import(/* webpackChunkName: "Footer" */ '../Footer'))


export default class App extends React.Component {
    render () {
        return (
            <div>
                <AppHead/>
                <Header />
                    <Routes />
                <Player />
                <Footer />
                <AlertError/>
            </div>
        );
    }
}
