import { 
	PODCAST_LIST_FETCHING,
	PODCAST_VIEW_LOADED, 
	PODCAST_VIEW_CLEAR, 
	PODCAST_TRACK_LOADED, 
	PODCAST_VIDEO_LOADED,
	PODCAST_INTERN_LOADED,
	PODCAST_EXTERN_LOADED 
} from '../Store/constants';

const initialState = {
	isFetching: false,
	intern: {
		index: [],
		ids: [],
		load: false,
		fetched: [],
		next: false
	},
	extern: {
		index: [],
		ids: [],
		load: false,
		fetched: [],
		next: false
	},
	view: {
		info: [],
		infoLoad: false,
		track: [],
		trackLoad: false,
		video: [],
		videoLoad: false,
	},
}

function podcastReducers (state = initialState, action) {
	switch(action.type) {
		case PODCAST_LIST_FETCHING: {
			return Object.assign({}, state, {
				isFetching: true,
			});
		}
		case PODCAST_INTERN_LOADED: {
			return Object.assign({}, state, {
			    isFetching: false,
			    intern: {
					index: state.intern.index.concat(action.content),
					ids: state.intern.ids.concat(action.ids),
					load: true,
					fetched: state.intern.fetched.concat(action.fetched),
					next: action.next
				},
			});
		}
		case PODCAST_EXTERN_LOADED: {
			return Object.assign({}, state, {
				isFetching: false,
			    extern: {
					index: state.extern.index.concat(action.content),
					ids: state.extern.ids.concat(action.ids),
					load: true,
					fetched: state.extern.fetched.concat(action.fetched),
					next: action.next
				},
			});
		}
		case PODCAST_VIEW_LOADED: {
			return Object.assign({}, state, {
			    view: {
					...state.view,
					info: action.content,
					infoLoad: true,
				}
			});
		}
		case PODCAST_TRACK_LOADED: {
			if (action.content) {
				return Object.assign({}, state, {
				    view: {
						...state.view,
						track: state.view.track.concat(action.content),
						trackLoad: true,
					}
				});
			} else {
				return Object.assign({}, state, {
		    	    view: {
		    			...state.view,
		    			trackLoad: true,
		    		}
			});
			}
		}
		case PODCAST_VIDEO_LOADED: {
			if (action.content) {
				return Object.assign({}, state, {
				    view: {
						...state.view,
						video: state.view.video.concat(action.content),
						videoLoad: true,
					}
				});
			} else {
				return Object.assign({}, state, {
		    	    view: {
		    			...state.view,
		    			videoLoad: true,
		    		}
			});
			}
		}
		case PODCAST_VIEW_CLEAR: {
			return {
				...state,
		    	view: {
		        	...initialState.view,
		        }
			};
		}
		default: 
            return state;
	}
};

export default podcastReducers;