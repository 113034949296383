import React from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga"
 
const TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
 
function init() {
  if (!TRACKING_ID) {
    console.log('Tracking not enabled, as `trackingId` was not given and there is no `REACT_APP_GA_MEASUREMENT_ID`.')
    return
  }
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development"
  ReactGA.initialize(TRACKING_ID, { debug: isDev })
}
 
function sendEvent(payload) {
  ReactGA.event(payload)
}
 
function sendPageview(path) {
  ReactGA.set({ page: path })
  ReactGA.pageview(path)
}
 
export default function useGoogleAnalytics() {
  const location = useLocation()
 
  React.useEffect(() => {
    init()
  }, [])
 
  React.useEffect(() => {
    const currentPath = location.pathname + location.search
    sendPageview(currentPath)
  }, [location]) 
}