const initialState = {
	token: false,
	expiration: false,
	refreshToken : false,
	error: []
}

function soundcloudAuthReducers(state = initialState, action) {
	// console.log(action);
	
	switch(action.type) {
		case "AUTH_SOUNDCLOUD_SUCCES": {
			return Object.assign({}, state, {
			    token: action.token,
			    expiration: action.expiration,
				refreshToken: action.refreshToken
			});
		}
		case "AUTH_SOUNDCLOUD_FAIL": {
			return {
				...state,
			    error: state.error.concat(action.payload),
			};
		}
		case "LOGOUT_SOUNDCLOUD_SUCCES": {
			return Object.assign({}, state, {
			    token: false,
			    expiration: false,
				refreshToken : false,
			});
		}
		default: 
            return state;
	}
};

export default soundcloudAuthReducers;